import React, { useState } from "react";
import MainHeader from "../components/Main-header";
import "../css/contact.css";

function Quote() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    country: "Pakistan",
    interest: "",
    message: "",
  });

  const [loading, setLoading] = useState(false); // Loading state
  const [formSubmitted, setFormSubmitted] = useState(false); // Submission success state
  const [isFormVisible, setIsFormVisible] = useState(true); // Form visibility state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure required fields are filled out
    if (
      !formData.fullName ||
      !formData.email ||
      !formData.phone ||
      !formData.message ||
      !formData.interest
    ) {
      alert("Please fill in all the required fields.");
      return;
    }

    setLoading(true); // Set loading to true

    // Simulate sending form data via POST request
    try {
      const response = await fetch("http://localhost:3001/sendmail/sendquote", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (data.success) {
        setFormSubmitted(true); // Indicate success
        setIsFormVisible(false); // Hide the form

        // Show the form again after 3 minutes (180,000 milliseconds)
        setTimeout(() => {
          setIsFormVisible(true); // Show form again
          setFormData({
            fullName: "",
            email: "",
            phone: "",
            country: "Pakistan",
            interest: "",
            message: "",
          }); // Reset form fields
          setFormSubmitted(false); // Reset form submission status
        }, 180000); // 3 minutes
      } else {
        alert("Failed to send email.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    } finally {
      setLoading(false); // Set loading to false when done
    }
  };

  return (
    <>
      <MainHeader>
        <div className="about-section position-relative">
          <video
            autoPlay
            preload="none"
            loop
            muted
            className="background-video position-absolute top-0 start-0 w-100 h-100"
            style={{
              objectFit: "cover",
              zIndex: -1,
              filter: "brightness(80%)",
            }}
          >
            <source src="/video/Main-heading.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <div
            className="container text-start text-light d-flex flex-column justify-content-center align-items-start"
            style={{ height: "320px" }}
          >
            <div className="content-box">
              <h1
                className="display-4 mb-4 fw-bold"
                data-aos="zoom-in-right"
                style={{ letterSpacing: "2px" }}
              >
                REQUEST A DEMO
              </h1>
            </div>
          </div>
        </div>
        <div className="container-fluid mb-5">
          <br></br>
          <br></br>
          <div className="container">
            <div className="row g-4">
              {/* Left Contact Section */}
              <div className="col-md-6 pt-5" data-aos="fade-right">
                <p id="contact-us-para">
                  This is your gateway to connect with us directly. Whether you
                  have inquiries, feedback, or simply want to say hello.
                </p>

                <hr />

                <div
                  className="row  mb-4 mt-4"
                  id="contact-location-main-div"
                >
                  <div
                    className="col-1 d-flex align-items-center justify-content-center p-3"
                    id="contact-location-icon-div"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="64"
                      height="64"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-map-pin"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                      <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                    </svg>{" "}
                  </div>
                  <div
                    className="col-10  m-0 p-0 ps-3"
                    id="contact-location-text-div"
                  >
                    <p className="m-0" id="address-heading">
                      Address
                    </p>
                    <p className="m-0" id="address-para">
                      Office No. 1017, Nur Khan Air Base, Rawalpindi, Pakistan
                    </p>
                  </div>
                </div>

                <div
                  className="row  mb-4"
                  id="contact-phone-main-div"
                >
                  <div
                    className="col-1 d-flex align-items-center justify-content-center p-3"
                    id="contact-phone-icon-div"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="64"
                      height="64"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-phone"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                    </svg>
                  </div>
                  <div
                    className="col-10 d-flex flex-column m-0 p-0 ps-3"
                    id="contact-phone-text-div"
                  >
                    <p className="m-0" id="phone-heading">
                      Phone
                    </p>
                    <p className="d-inline-block m-0 p-0">
                      <a
                        href="tel:+923005676400"
                        className=""
                        id="first-number"
                      >
                        051-8899652
                      </a>
                    </p>
                  </div>
                </div>

                <div
                  className="row"
                  id="contact-mail-main-div"
                >
                  <div
                    className="col-1 d-flex align-items-center justify-content-center p-3"
                    id="contact-mail-icon-div"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="64"
                      height="64"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-mail"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                      <path d="M3 7l9 6l9 -6" />
                    </svg>
                  </div>
                  <div
                    className="col-10 d-flex flex-column m-0 p-0 ps-3"
                    id="contact-mail-text-div"
                  >
                    <p className="m-0 p-0" id="email-heading">
                      Email
                    </p>
                    <p className="m-0 p-0">
                      <a href="mailto:gccisbpk@gmail.com">
                        info@geleceksoln.com
                      </a>
                    </p>
                  </div>
                </div>

                <hr />

                <div className="row ps-4">
                  <div
                    className="col-lg-2 d-flex justify-content-center align-items-center me-3"
                    id="facebook_icon"
                  >
                    <a
                      href="https://www.facebook.com/Gccisb?mibextid=ZbWKwL"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/img/socialmedia/facebook.svg"
                        alt=""
                        width="25"
                        height="25"
                      />
                    </a>
                  </div>

                  <div
                    className="col-lg-2 d-flex justify-content-center align-items-center me-3"
                    id="instagram_icon"
                  >
                    <a href="#">
                      <img
                        src="/img/socialmedia/instagram.svg"
                        alt=""
                        width="25"
                        height="25"
                      />
                    </a>
                  </div>

                  <div
                    className="col-lg-2 d-flex justify-content-center align-items-center me-3"
                    id="linkedin_icon"
                  >
                    <a
                      href="https://www.tiktok.com/@toto83766?_t=8pYW3k6qYW0&_r=1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/img/socialmedia/linkedin.svg"
                        alt=""
                        width="25"
                        height="25"
                      />
                    </a>
                  </div>

                  <div
                    className="col-lg-2 d-flex justify-content-center align-items-center me-3"
                    id="twitter_icon"
                  >
                    <a
                      href="https://www.youtube.com/@pastoryounasboota5153"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/img/socialmedia/twitter.svg"
                        alt=""
                        width="25"
                        height="25"
                      />
                    </a>
                  </div>
                </div>
              </div>

              {/* Right Contact Section */}
              <div className="col-md-6" id="form-main-div" data-aos="fade-left">
                <div id="form-div" className="p-4">
                  {isFormVisible ? (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-6">
                          <div className="mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Full Name"
                              name="fullName"
                              value={formData.fullName}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="mb-3">
                            <input
                              type="email"
                              className="form-control"
                              placeholder="name@example.com"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="mb-3">
                            <input
                              type="tel"
                              className="form-control"
                              placeholder="Phone Number"
                              name="phone"
                              value={formData.phone}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Country Name"
                              name="country"
                              value={formData.country}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="mb-3">
                            <select
                              className="form-select"
                              name="interest"
                              value={formData.interest}
                              onChange={handleChange}
                            >
                              <option selected>
                                What are you interested in?
                              </option>
                              <option value="Aura-Mind">
                              Aura Mind
                              </option>
                              <option value="Safe-City">
                              Safe City
                              </option>
                              <option value="GELCHECK-Verification-System">GELCHECK Verification System</option>
                              <option value="CCTV-Surveillance-&-Security-System">
                              CCTV Surveillance & Security System
                              </option>
                              <option value="Enexa e-Office">
                              Enexa e-Office
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <textarea
                          className="form-control"
                          rows="3"
                          placeholder="Enter Message"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}
                      >
                        {loading ? "Sending..." : "Send"}
                      </button>
                    </form>
                  ) : (
                    formSubmitted && (
                      <p className="text-success mt-3">
                        Your message was sent successfully!
                      </p>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainHeader>
    </>
  );
}
export default Quote;
