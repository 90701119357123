import React from "react";
import MainHeader from "../Main-header";
import Servicecontactform from "./servicecontactform";
import "../../css/services.css";

const identityaccess = () => {
  return (
    <MainHeader>
    <div className="container-fluid p-0 m-0">
      <div className="container pt-5 pb-5">
        <div className="row">
          {/* Left Section */}
          <div className="col-md-7 d-flex flex-column" id="oracle_left_div"  data-aos="fade-right">
            <p id="service_heading">Identity and Access Management</p>
            <p id="services_para">
              Identity and Access Management (IAM) services protect your organization by ensuring the right individuals have access to the right resources. We offer comprehensive solutions, including user authentication, access controls, and identity governance, to safeguard your systems and data while maintaining operational efficiency and compliance.
            </p>
            <a href="#" id="custom_quote_btn" className="">Custom Quote</a>
            <p className="mt-4" id="or_call_us_btn">
              or Call Us: <a href="tel:+923345592388" className="">+92 334 5592388</a>
            </p>
          </div>

          {/* Right Section */}
          <div className="col-md-5" data-aos="fade-left">
            <Servicecontactform></Servicecontactform>
          </div>
        </div>
      </div>
      <video
          autoPlay
          preload="none"
          poster="/img/other-pages-poster.png"
          loop
          muted
          className="background-video position-absolute top-0 start-0 w-100 h-100"
          style={{
            objectFit: "cover",
            zIndex: -1,
            filter: "brightness(80%)",
          }}
        >
          <source src="/video/Main-heading.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

      {/* Oracle Description Section Start */}
      <div className="container mt-5">
        <br></br>
        <br></br>
        <div className="row">
          <div className="col-md-5" id="oracle_description_image_div">
            <img src="/img/services/Identity_Management_img.jpg" alt=""  data-aos="fade-right" loading="lazy" />
          </div>
          <div className="col-md-7" data-aos="fade-left">
            <p id="oracle_description_heading">Securing Inotechs digital ecosystem with intuitive Identity and Access Management</p>
            <p id="oracle_description_para">
              Identity and Access Management (IAM) refers to the set of processes, policies, and technologies that organizations implement to ensure appropriate access to their resources, systems, and data. IAM systems help organizations manage user identities, enforce access controls, and maintain the security and integrity of their digital assets.
            </p>
            <p id="oracle_description_para1"><span>Identity Lifecycle Management: -</span> IAM systems facilitate the creation, modification, and retirement of user identities throughout their lifecycle within an organization. This includes processes such as user provisioning, deprovisioning, role-based access control (RBAC), and self-service user management.</p>
            <p id="oracle_description_para1"><span>User Authentication and Single Sign-On (SSO): -</span> IAM solutions provide secure authentication mechanisms to verify user identities. This may include password-based authentication, multi-factor authentication (MFA), biometric authentication, or integration with external identity providers. Single Sign-On enables users to authenticate once and access multiple systems or applications without the need to re-enter credentials.</p>
            <p id="oracle_description_para1"><span>Access Control and Authorization: -</span> IAM systems enforce access controls based on user roles, responsibilities, and permissions. They ensure that users can only access the resources and data necessary for their job function or level of authorization. Fine-grained access control mechanisms allow organizations to define and enforce granular access policies to protect sensitive information.</p>
            <p id="oracle_description_para1"><span>Identity Governance and Administration: -</span> IAM solutions enable organizations to centrally manage user identities and access rights. Identity governance involves defining policies, roles, and rules to ensure compliance with regulations and internal security requirements. Identity administration streamlines user onboarding, offboarding, and role assignment processes, ensuring efficient and accurate management of user access privileges.</p>
            <p id="oracle_description_para3">Identity and Access Management is crucial in today's digital landscape to ensure secure and efficient management of user identities and access privileges. By implementing robust IAM solutions, organizations can protect their critical assets, maintain regulatory compliance, and enable seamless access for authorized users while mitigating security risks.</p>
          </div>
        </div>
      </div>
      {/* Oracle Description Section End */}

      {/* Oracle FAQs Section Start */}
      <div className="container mt-5" data-aos="zoom-in">
        <p id="faq_heading">Frequently Asked Questions</p>
        <div className="accordion accordion-flush mt-5" id="accordionFlushExample">
          <div className="accordion-item pt-3 pb-3">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                What is Identity and Access Management (IAM)?
              </button>
            </h2>
            <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                IAM refers to a framework of policies and technologies that ensure the right individuals can access the right resources at the right time, while protecting your organization's systems and data from unauthorized access.
              </div>
            </div>
          </div>
          <div className="accordion-item pt-3 pb-3">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                How does IAM improve security within my organization?
              </button>
            </h2>
            <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                IAM enhances security by implementing strong authentication methods, managing user identities, and controlling access to critical resources, reducing the risk of data breaches and insider threats.
              </div>
            </div>
          </div>
          <div className="accordion-item pt-3 pb-3">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                Can IAM be integrated with our existing systems?
              </button>
            </h2>
            <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                Yes, our IAM solutions are designed to seamlessly integrate with your existing systems, including enterprise applications, cloud platforms, and third-party services, ensuring consistent security across your IT environment.
              </div>
            </div>
          </div>
          <div className="accordion-item pt-3 pb-3">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                What is multi-factor authentication, and how does it fit into IAM?
              </button>
            </h2>
            <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                Multi-factor authentication (MFA) requires users to provide two or more verification methods before accessing systems, adding an extra layer of security. It's a key component of IAM to prevent unauthorized access.
              </div>
            </div>
          </div>
          <div className="accordion-item pt-3 pb-3">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                Do you provide ongoing support for IAM solutions?
              </button>
            </h2>
            <div id="flush-collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                Yes, we offer continuous support, including regular updates, system monitoring, and user training, to ensure your IAM system remains effective and up-to-date with evolving security needs.
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Oracle FAQs Section End */}
    </div>
    </MainHeader>
  );
};

export default identityaccess;
