import React, { useState } from "react";
import { Link } from "react-router-dom";
import MainHeader from "../components/Main-header";
import "../css/products.css";

function products() {
  const products = [
    {
      id: 1,
      name: "Aura Mind",
      image: "/img/products/auramind.jpeg",
      shortDescription:
        "AuraMind is a Gen-AI based application where users can interact with their PDFs.",
    },
    {
      id: 2,
      name: "Safe City",
      image: "/img/products/SafeCity.png",
      shortDescription:
        "SafeCity is an AI-Based project for real-time traffic monitoring using live CCTV feeds.",
    },
    {
      id: 3,
      name: "GELCHECK Verification System",
      image: "/img/products/gelecheck.jpeg",
      shortDescription:
        "Ensures enhanced security, reduced fraud, and fast access with seamless integration.",
    },
    {
      id: 4,
      name: "CCTV Surveillance & Security System",
      image: "/img/products/CCTVSS.jpeg",
      shortDescription:
        "We have deployed and engaged in maintaining medium to enterprise size CCTV surveillance systems consisting of hundreds of IP based cameras and allied paraphernalia.",
    },
    {
      id: 5,
      name: "Enexa e-Office",
      image: "/img/services/Enexa.png",
      shortDescription:
        "SafceCity is an AI-Based project can help in Real-Time monitoring of the traffic insights using the live CCTV Feeds.",
    },
  ];

  const formatNameForUrl = (name) => {
    return name.toLowerCase().replace(/\s+/g, "-");
  };
  return (
    <>
      <MainHeader>
        <div className="about-section position-relative">
          <video
            autoPlay
            preload="none"
            loop
            muted
            className="background-video position-absolute top-0 start-0 w-100 h-100"
            style={{
              objectFit: "cover",
              zIndex: -1,
              filter: "brightness(80%)",
            }}
          >
            <source src="/video/Main-heading.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <div
            className="container text-start text-light d-flex flex-column justify-content-center align-items-start"
            style={{ height: "320px" }}
          >
            <div className="content-box">
              <h1
                className="display-4 mb-4 fw-bold"
                data-aos="zoom-in-right"
                style={{ letterSpacing: "2px" }}
              >
                PRODUCTS
              </h1>
            </div>
          </div>
        </div>
        {/* About Us Section */}
        <div style={{ backgroundColor: "#F8F6F3" }}>
          <div className="container-fluid pt-5 pb-5">
            <div className="container">
              <div className="row mb-5">
                <div className="col-sm-12">
                  <p className="lead text-center">
                    At{" "}
                    <span className="text-warning">
                      Gelecek Solutions Pvt Ltd
                    </span>
                    , we take pride in developing innovative products that cater
                    to the ever-evolving needs of businesses. Our products are
                    designed to enhance productivity, streamline operations, and
                    drive growth. Here are some key highlights of our product
                    offerings:
                    <br></br>
                    <br></br>
                    We continuously strive to develop and refine our products to
                    meet the dynamic needs of our clients. With our expertise
                    and commitment to excellence, we deliver reliable,
                    user-friendly, and future-proof solutions that drive success
                    for businesses across industries.
                  </p>
                </div>
              </div>
              <div className="row">
                {products.map((product) => (
                  <div className="col-sm-4 mb-4" data-aos="zoom-in" key={product.id}>
                    <div className="bg-white rounded-3 p-3 product-card">
                      <img
                        src={product.image}
                        className="w-100 rounded-3"
                        alt={product.name}
                      />
                      <h4 className="mt-2">{product.name}</h4>
                      <p>{product.shortDescription}</p>
                      <a
                        href={`/product/${formatNameForUrl(product.name)}`}
                        className="btn btn-outline-dark w-100"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </MainHeader>
    </>
  );
}
export default products;
