import React, { useState } from "react";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [formVisible, setFormVisible] = useState(true); // To toggle form visibility
  const [loading, setLoading] = useState(false); // To manage loading state

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submitting

    // Logging form data to the console (replace this with actual submission logic)
    console.log("Form submitted:", formData);

    // POST request to the backend (replace with actual URL)
    try {
      const response = await fetch("https://mynode.geleceksoln.com/sendmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (data.success) {
        alert("Email sent successfully!");

        // Hide the form
        setFormVisible(false);

        // Re-show the form after 3 minutes
        setTimeout(() => {
          setFormVisible(true);
          setFormData({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          }); // Reset form data
        }, 180000); // 3 minutes = 180000 milliseconds
      } else {
        alert("Failed to send email.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    } finally {
      setLoading(false); // Reset loading state after submission
    }
  };

  return (
    <div id="form-div" className="p-4">
      {formVisible ? (
        <form onSubmit={handleSubmit}>
          <div className="row g-3">
            <div className="col-6">
              <div className="mb-3">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Full Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="col-6">
              <div className="mb-3">
                <input
                  type="tel"
                  name="phone"
                  className="form-control"
                  placeholder="Phone Number"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="mb-3">
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder="name@example.com"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              name="subject"
              className="form-control"
              placeholder="Enter Subject"
              value={formData.subject}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-3">
            <textarea
              className="form-control"
              name="message"
              rows="3"
              placeholder="Enter Message"
              value={formData.message}
              onChange={handleInputChange}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            ) : (
              "Send"
            )}
          </button>
        </form>
      ) : (
        <p className="text-white">Form submitted successfully. Please wait while we process your request.</p>
      )}
    </div>
  );
};

export default ContactForm;
