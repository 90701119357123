import React from "react";
import { useParams, Link } from "react-router-dom";
import MainHeader from "../components/Main-header";

const ProductDetail = () => {
  const { name } = useParams(); // Get product name from the URL

  const products = [
    {
      id: 1,
      name: "Aura Mind",
      image: "/img/products/auramind.jpeg",
      shortDescription: `
          <p>
            AuraMind is a Gen-AI based application where users can utilize our services to interact with their own single or multiple PDFs using our online platform.
          </p>
          <h5><strong>Benefits</strong></h5>
          <ul>
            <li>Catering to Diverse Industries</li>
            <li>Effortless Processing</li>
            <li>Simplifying Complex Data</li>
            <li>Detailed Analysis</li>
            <li>Utilization of NLP</li>
            <li>Technical Document Explanation</li>
          </ul>
          <h5><strong>Applications</strong></h5>
          <p>
            AuraMind can be used in multiple applications according to the needs of the problems, some of them are:
          </p>
          <ul>
            <li>MEDICAL</li>
            <li>ACADEMIA</li>
            <li>LAW</li>
            <li>HR INDUSTRY</li>
            <li>LAND RECORDS</li>
            <li>WEB CHATBOT</li>
          </ul>
        `,
    },
    {
      id: 2,
      name: "Safe City",
      image: "/img/products/SafeCity.png",
      shortDescription: `
          <p>
            SafeCity is an AI-Based project that can help in real-time monitoring of traffic insights using live CCTV feeds.
          </p>
          <h5><strong>Benefits</strong></h5>
          <ul>
            <li>Realtime valuable Insights</li>
            <li>Can record Ingoing/Outgoing Cars</li>
            <li>Multiple Input Formats</li>
            <li>Detailed Analysis</li>
            <li>Record Saving</li>
            <li>Can help in Counting of objects</li>
          </ul>
          <h5><strong>Applications</strong></h5>
          <p>
            SafeCity-based AI project can be utilized in multiple scenarios, based on custom requirements, such as:
          </p>
          <ul>
            <li>Shopping Malls</li>
            <li>Sensitive Places</li>
            <li>Banks</li>
            <li>Highway Roads</li>
            <li>Traffic Signals</li>
            <li>Housing Schemes</li>
          </ul>
        `,
    },
    {
      id: 3,
      name: "GELCHECK Verification System",
      image: "/img/products/gelecheck.jpeg",
      shortDescription:
        "In todays dynamic digital environment, GELCHECK verification system ensures enhanced security, reduced fraud, fast and easy access, seamless integration, and reliable and accurate, high-precision results. This robust system can be deployed anywhere for authenticating verifications, like examination centers and high-risk security areas.",
    },
    {
      id: 4,
      name: "CCTV Surveillance & Security System",
      image: "/img/products/CCTVSS.jpeg",
      shortDescription:
        "We have deployed and engaged in maintaining medium to enterprise size CCTV surveillance systems consisting of hundreds of IP based cameras and allied paraphernalia. We deployed surveillance system that allow our customers to monitor multiple locations from a single Security Operations Center (SOC).",
    },
    {
      id: 5,
      name: "Enexa e-Office",
      image: "/img/services/Enexa.png",
      shortDescription:
        "AOS is an office automation software that streamlines document management, chat, and notification verification. It enables efficient handling of files, secure messaging for team collaboration, and real-time notifications, ensuring tasks are managed and verified promptly.",
    },
  ];

  const formatNameForComparison = (name) => {
    return name.toLowerCase().replace(/\s+/g, "-");
  };

  // Find the product that matches the name from the URL
  const product = products.find(
    (p) => formatNameForComparison(p.name) === name
  );

  if (!product) {
    return <p>Product not found</p>;
  }

  return (
    <MainHeader>
      <div style={{ backgroundColor: "#F8F6F3"}}>
        <div className="container-fluid pt-5 pb-5">
          <div className="container">
            <div className="row mb-3" data-aos="fade-in">
              <div className="col-12 d-flex align-center">
                <Link to="/products" className="btn btn-outline-dark">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icons-tabler-outline icon-tabler-chevron-left"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M15 6l-6 6l6 6" />
                  </svg>
                </Link>
                <span className="p-0 m-0 ms-3 fs-2">
                  <strong> {product.name}</strong>
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8" data-aos="fade-up">
                <div className="bg-white rounded-3 p-3">
                  <img
                    src={product.image}
                    className="w-100 rounded-3"
                    alt={product.name}
                  />
                  <br />
                  <br />
                  <h3>
                    <strong>Description:</strong>{" "}
                  </h3>
                  <div
                    className="mt-3"
                    dangerouslySetInnerHTML={{
                      __html: product.shortDescription,
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-4" data-aos="fade-left">
                <div className="bg-white rounded-3 p-3 h-100">
                  <h3>
                    <strong>Frontend Screen Shot:</strong>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainHeader>
  );
};

export default ProductDetail;
