import React from "react";
import MainHeader from "../Main-header";
import Servicecontactform from "./servicecontactform";
import "../../css/services.css";

const BusinessIntelligence = () => {
  return (
    <MainHeader>
      <div>
        {/* Oracle Form Page Banner Start */}
        <div className="container-fluid p-0 m-0" id="Oracle-form-banner">
          <div className="container pt-5 pb-5">
            <div className="row">
              {/* Left Section */}
              <div className="col-md-7 d-flex flex-column" id="oracle_left_div" data-aos="fade-right">
                <p id="service_heading">Business Intelligence</p>
                <p id="services_para">
                  Business Intelligence services empower your organization with
                  data-driven insights for better decision-making. We provide
                  comprehensive solutions, including data analysis, reporting,
                  and visualization tools, to help you turn raw data into
                  actionable intelligence and stay ahead in a competitive
                  market.
                </p>
                <a href="#" id="custom_quote_btn" className="">
                  Custom Quote
                </a>
                <p className="mt-4" id="or_call_us_btn">
                  or Call Us: <a href="tel:+923345592388">+92 334 5592388</a>
                </p>
              </div>

              {/* Right Section */}
              <div className="col-md-5" data-aos="fade-left">
                <Servicecontactform></Servicecontactform>
              </div>
            </div>
          </div>
          <video
          autoPlay
          preload="none"
          poster="/img/other-pages-poster.png"
          loop
          muted
          className="background-video position-absolute top-0 start-0 w-100 h-100"
          style={{
            objectFit: "cover",
            zIndex: -1,
            filter: "brightness(80%)",
          }}
        >
          <source src="/video/Main-heading.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        </div>
        {/* Oracle Form Page Banner End */}

        {/* Oracle Description Section Start */}
        <div className="container mt-5 descservice">
          <div className="row">
            <div className="col-md-5" id="oracle_description_image_div"  data-aos="fade-right">
              <img src="/img/services/Business_Intelligence_img.jpg" alt="" loading="lazy" />
            </div>
            <div className="col-md-7"  data-aos="fade-left">
              <p id="oracle_description_heading">
                Unleashing Inotechs full potential with intelligent insights
                through Business Intelligence
              </p>
              <p id="oracle_description_para">
                Business Intelligence (BI) refers to the processes,
                technologies, and applications used to gather, analyze, and
                transform raw data into meaningful insights that drive informed
                business decisions. It involves the collection, integration, and
                analysis of data from various sources to provide actionable
                information and facilitate data-driven decision-making within an
                organization.
              </p>

              <p id="oracle_description_para1">
                <span>Data Collection and Integration: -</span> BI systems
                aggregate data from multiple sources, such as databases,
                spreadsheets, enterprise applications, social media, and
                external sources. These diverse datasets are collected and
                integrated into a unified data repository, ensuring data
                consistency and accessibility for analysis.
              </p>

              <p id="oracle_description_para1">
                <span>Data Warehousing: -</span> BI often involves the creation
                of data warehouses or data marts, which are optimized structures
                for storing and organizing large volumes of data. These
                repositories enable efficient data retrieval and provide a
                foundation for performing complex queries and analysis.
              </p>

              <p id="oracle_description_para1">
                <span>Data Analysis and Reporting: -</span> BI tools offer a
                wide range of data analysis techniques, including data mining,
                statistical analysis, forecasting, and predictive modeling.
                Analysts and business users can explore data, identify patterns,
                trends, and correlations, and generate reports and
                visualizations to communicate insights effectively.
              </p>

              <p id="oracle_description_para1">
                <span>Dashboards and Visualizations: -</span> BI platforms
                provide interactive dashboards and visualizations to present
                data in a visually appealing and easily understandable format.
                Dashboards consolidate key performance indicators (KPIs) and
                metrics, enabling users to monitor real-time data and track
                performance against predefined targets.
              </p>

              <p id="oracle_description_para3">
                Business Intelligence plays a crucial role in modern
                organizations, enabling them to unlock the value of their data
                and make informed decisions to drive growth, innovation, and
                competitive advantage. By leveraging BI tools and practices,
                organizations can harness their data assets, transform raw
                information into actionable insights, and optimize their
                operations and strategies for success.
              </p>
            </div>
          </div>
        </div>
        {/* Oracle Description Section End */}

        {/* Oracle FAQs Section Start */}
        <div className="container mt-5" data-aos="zoom-in-right">
          <p id="faq_heading">
            Frequently Asked Questions
          </p>

          <div
            className="accordion accordion-flush mt-5"
            id="accordionFlushExample"
          >
            <div className="accordion-item pt-3 pb-3">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  What is Business Intelligence (BI), and how can it benefit my
                  organization?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Business Intelligence (BI) involves collecting, analyzing, and
                  visualizing data to help organizations make informed
                  decisions. BI solutions enhance operational efficiency,
                  identify market trends, and provide actionable insights to
                  drive business growth.
                </div>
              </div>
            </div>
            <div className="accordion-item pt-3 pb-3">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  What types of data can be analyzed using BI tools?
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  BI tools can analyze various types of data, including sales
                  figures, customer behavior, financial performance, supply
                  chain metrics, and more, providing a holistic view of your
                  business operations.
                </div>
              </div>
            </div>
            <div className="accordion-item pt-3 pb-3">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  How do you ensure data accuracy in your BI solutions?
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  We ensure data accuracy by implementing robust data validation
                  processes, cleansing techniques, and integrating data from
                  multiple reliable sources to provide precise and consistent
                  insights.
                </div>
              </div>
            </div>
            <div className="accordion-item pt-3 pb-3">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  Can BI solutions be customized for my specific business needs?
                </button>
              </h2>
              <div
                id="flush-collapseFour"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Yes, our BI solutions are fully customizable to meet your
                  organization’s unique needs, including tailored reports,
                  dashboards, and KPIs to help you monitor the metrics most
                  relevant to your business goals.
                </div>
              </div>
            </div>
            <div className="accordion-item pt-3 pb-3">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFive"
                  aria-expanded="false"
                  aria-controls="flush-collapseFive"
                >
                  Do you offer ongoing support and training for BI tools?
                </button>
              </h2>
              <div
                id="flush-collapseFive"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Yes, we provide continuous support and training to ensure your
                  team can effectively use BI tools, interpret data insights,
                  and adapt the solution as your business evolves.
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Oracle FAQs Section End */}
      </div>
    </MainHeader>
  );
};

export default BusinessIntelligence;
