import React from "react";
import MainHeader from "../components/Main-header";
import "../css/aboutus.css";

function aboutus() {
  return (
    <>
      <MainHeader>
        <div className="about-section position-relative">
          <video
            autoPlay
            preload="none"
            loop
            muted
            className="background-video position-absolute top-0 start-0 w-100 h-100"
            style={{
              objectFit: "cover",
              zIndex: -1,
              filter: "brightness(80%)",
            }}
          >
            <source src="/video/Main-heading.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <div
            className="container text-start text-light d-flex flex-column justify-content-center align-items-start"
            style={{ height: "320px" }}
          >
            <div className="content-box">
              <h1
                className="display-4 mb-4 fw-bold" data-aos="zoom-in-right"
                style={{ letterSpacing: "2px" }}
              >
                ABOUT US
              </h1>
            </div>
          </div>
        </div>
        {/* About Us Section */}

        <div className="container-fluid m-0 p-0" id="about-us-section" data-aos="fade-up">
          <div className="container pt-5" id="about-us-paragraph">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <p id="about-us-main-para">
                  Welcome to the digital realm where innovation meets expertise,
                  and every line of code is a brushstroke on the canvas of
                  tomorrow.
                </p>
              </div>
              <div className="col-md-2"></div>
            </div>

            <div className="row mt-5">
              <div
                className="col-md-5 d-flex justify-content-center"
                id="about-us-logo-div"   data-aos="fade-right"
              >
                <img
                  src="/img/GelecekLogo.png"
                  alt=""
                  width="400px"
                  height="400px" loading="lazy"
                />
              </div>
              <div
                className="col-md-7 d-flex flex-column align-items-center"
                id="about-us-logo-text"  data-aos="fade-left"
              >
                <p id="about-us-text-1">
                  At Gelecek, we don’t just create software; we craft
                  experiences that push the boundaries of what's possible. Our
                  passion for innovation transforms technology into a canvas
                  where your ideas take center stage, and every solution becomes
                  a unique masterpiece. Whether it's cutting-edge software
                  development or hardware integration, we are committed to
                  shaping technology that aligns with your vision, ensuring that
                  every project reflects precision and creativity.
                </p>
                <p id="about-us-text-2">
                  As a leading IT equipment supplier, we go beyond simply
                  providing products. We deliver a full range of top-tier
                  hardware solutions, specializing in CCTV equipment and
                  implementation. Our expertise spans across industries,
                  offering tailored solutions that meet your specific needs.
                </p>
                <p id="about-us-text-3">
                  More than just a software house, Gelecek is the forge where
                  your tech dreams become reality. Together, we can code a
                  future where technology serves your ambitions.
                </p>
              </div>
            </div>
          </div>

          {/* Our Partners Section */}
          <div className="container-fluid pt-3 pb-5" id="our-partner-main-div">
            <div className="container mt-5">
              <div
                id="logoCarousel"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  {/* Carousel Item 1 */}
                  <div className="carousel-item active">
                    <div className="d-flex justify-content-around">
                      <img src="/img/partner-logo/1.png" alt="Logo 1" loading="lazy" />
                      <img src="/img/partner-logo/2.png" alt="Logo 2" loading="lazy" />
                      <img
                        id="img-3"
                        src="/img/partner-logo/3.png"
                        alt="Logo 3" loading="lazy"
                      />
                      <img src="/img/partner-logo/4.png" alt="Logo 4" loading="lazy" />
                    </div>
                  </div>
                  {/* Carousel Item 2 */}
                  <div className="carousel-item">
                    <div className="d-flex justify-content-around">
                      <img src="/img/partner-logo/5.png" alt="Logo 5" loading="lazy" />
                      <img
                        id="img-6"
                        src="/img/partner-logo/6.png"
                        alt="Logo 6" loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="d-flex justify-content-around">
                      <img src="/img/partner-logo/7.png" alt="Logo 5" loading="lazy" />
                      <img
                        id="img-6"
                        src="/img/partner-logo/8.jpg"
                        alt="Logo 6" loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="d-flex justify-content-around">
                      <img src="/img/partner-logo/9.png" alt="Logo 5" loading="lazy" />
                      <img
                        id="img-6"
                        src="/img/partner-logo/10.png"
                        alt="Logo 6" loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="d-flex justify-content-around">
                      <img src="/img/partner-logo/11.png" alt="Logo 5" loading="lazy" />
                      <img
                        id="img-6"
                        src="/img/partner-logo/12.png"
                        alt="Logo 6" loading="lazy"
                      />
                    </div>
                  </div>
                </div>

                {/* Controls */}
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#logoCarousel"
                  data-bs-slide="prev"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-left"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M15 6l-6 6l6 6" />
                  </svg>{" "}
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#logoCarousel"
                  data-bs-slide="next"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 6l6 6l-6 6" />
                  </svg>

                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>

          {/* Our Location Section */}
          <div className="container-fluid m-0 p-0" id="our-location-map-div">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3322.891072363111!2d73.09768621118735!3d33.60813064093915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfeb8c67a97d8b%3A0x47a39a9b3da1914!2sGelecek%20Solutions!5e0!3m2!1sen!2s!4v1727767398915!5m2!1sen!2s"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>

          {/* Do You Have Question Section */}
          <div
            className="container-fluid pt-5 pb-5"
            id="do-you-have-question-main-div"
          >
            <div className="container" >
              <p id="do-you-have-question-heading" className="text-center" data-aos="fade-up"
     data-aos-duration="3000">
                Do you have any Question?
              </p>
              <p id="do-you-have-question-para1" className="text-center" data-aos="fade-up"
     data-aos-duration="3000">
                Artimization team believes in entertaining our visitors and is
                ready to answer your questions.
                <br />
                Type down your thoughts and get perfect possible solutions to
                your questions.
              </p>
              <div
                id="do-you-have-question-buttons"
                className="row justify-content-center"
              >
                <div className="col-sm-3">
                  <a href="/contact-us" className="btn w-100" id="contact-us-button" data-aos="fade-up"
     data-aos-duration="3000">
                    Contact Us
                  </a>
                </div>
                {/* <div className="col-sm-3">
                  <a
                    href="#"
                    className="btn w-100"
                    id="business-profile-button"
                  >
                    Business Profile
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </MainHeader>
    </>
  );
}
export default aboutus;
